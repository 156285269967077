<template>
  <component 
    :is="vue_select" 
    :options="networks_avaibles" 
    label="text"
    v-model="select_network"
    class="select-network-search"
    :clearable="false"
  >
    <template #option="{ text, value }">
      <div class="d-flex align-items-center">
        <b-avatar class="size-16-icons">
          <b-img :src="getIconWhite(value)" :class="getIconColor(value, false)" fluid></b-img>
        </b-avatar>
        <div class="avenir-medium ml-05">{{ text }}</div>
      </div>
    </template>

    <template #selected-option="{ value, text }">
      <div class="d-flex align-items-center">
        <b-avatar class="size-28-icons">
          <b-img :src="getIconWhite(value)" :class="getIconColor(value)" fluid></b-img>
        </b-avatar>
        <div class="avenir-medium ml-05 d-lg-none">{{ text }}</div>    
      </div>
    </template>
  </component>
</template>

<script>
import {
  BImg,
  BAvatar,
} from 'bootstrap-vue'
import { getIconWhite, getIconColor } from '@/libs/utils/icons';

export default {
  name: 'selectNetworkSearch',
  props: {
    filters: {
      type: Array,
      default: () => []
    },
    networks_avaibles: {
      type: Array,
      default: () => {
        return []
      }
    },
    select_network_dad: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {
    BImg,
    BAvatar,
  },
  data () {
    return {
      getIconColor,
      getIconWhite,
      vue_select: null,
      select_network: this.select_network_dad
    }
  },
  async mounted() {
    this.vue_select = (await import('vue-select')).default;
  },
  watch: {
    select_network(val) {
      this.$emit('update_select_network', val)
    },
  }
}
</script>
<style lang="scss" scoped>
.select-network-search {
  width: 80px;
  margin-right: 1em;
  @media(max-width: 991px) {
    border: 1px solid #E2E8F0;
    border-radius: 0.7em;
    margin-bottom: 1em;
    margin-right: 0em;
  }
}
.size-16-icons {
  width: 16px;
  height: 16px;
}
.size-28-icons {
  width: 28px;
  height: 28px;
}
</style>
<style>
.select-network-search .vs__search::placeholder,
.select-network-search .vs__dropdown-toggle
 {
  border: none;
  box-shadow: none;
  height: 46px ;
}
.select-network-search .vs__search {
  opacity: 0;
}
.ml-05 {
  margin-left: 0.5em;
}
.icon-twitter-profile-connection {
  width: 16px;
  height: 16px;
  background-color: black;
  border-radius: 100%;
}
.icon-twitter-profile-connection-28 {
  width: 28px;
  height: 28px;
  background-color: black;
  border-radius: 100%;
}
</style>
